// components/Modal.jsx
import React from 'react';
import { X } from 'lucide-react';

const Modal = ({ isOpen, onClose, children }) => {
  if (!isOpen) return null;
  
  return (
    <div 
      className="fixed inset-0 bg-black bg-opacity-50 z-50 overflow-auto"
      onClick={onClose}
    >
      <div className="min-h-screen px-4 py-8 text-center">
        <div className="flex items-center justify-center min-h-full">
          <div 
            className="bg-white rounded-lg w-full max-w-md inline-block align-middle relative"
            onClick={e => e.stopPropagation()}
          >
            <button
              onClick={onClose}
              className="absolute -right-2 -top-2 w-8 h-8 bg-white rounded-full shadow-lg flex items-center justify-center hover:bg-gray-100 transition-colors"
            >
              <X size={16} />
            </button>
            <div 
              className="max-h-[70vh] overflow-y-auto p-6 custom-scrollbar"
              style={{
                scrollbarWidth: 'thin',
                scrollbarColor: '#E5E7EB transparent',
              }}
            >
              {children}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Modal;