// components/ChatroomFeatures.jsx
import React from 'react';
import FeatureCard from './FeatureCard';

const ChatroomFeatures = () => {
  const features = [
    {
      title: "快適きれいな事務所",
      description: "ドリンクバー完備、お菓子食べ放題！リラックスして働ける環境をご用意しています。",
      imageUrl: "/api/placeholder/400/300",
      icon: "☕"
    },
    {
      title: "清潔なチャットルーム",
      description: "完全防音個室～身バレ対策のため写真は控えめですが、見学・体験入店で実際の快適さをご確認ください！",
      imageUrl: "/api/placeholder/400/300",
      icon: "✨"
    },
    {
      title: "充実の待機室",
      description: "Wi-Fi完備、ふかふかソファーでくつろげるラウンジスペース。まるでカフェのような居心地の良さです。",
      imageUrl: "/api/placeholder/400/300",
      icon: "🛋️"
    }
  ];

  return (
    <section className="py-16 px-4 md:px-8 bg-white">
      <div className="max-w-7xl mx-auto">
        <h2 className="text-3xl font-bold text-center mb-4">もえみ会チャットルームの特徴</h2>
        <p className="text-center text-gray-600 mb-12">
          快適な環境で、安心して働けます
        </p>
        <div className="grid grid-cols-1 md:grid-cols-3 gap-8">
          {features.map((feature, index) => (
            <FeatureCard key={index} {...feature} />
          ))}
        </div>
      </div>
    </section>
  );
};

export default ChatroomFeatures;