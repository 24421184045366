// components/ActiveMembers.jsx
import React from 'react';

const ActiveMembers = () => {
  return (
    <section className="py-16 px-4 md:px-8 bg-gradient-to-br from-purple-50 to-pink-50">
      <div className="max-w-7xl mx-auto">
        <h2 className="text-3xl font-bold text-center mb-12">どんな人が活躍中？</h2>
        <div className="grid grid-cols-1 md:grid-cols-2 gap-8">
          <div className="bg-white rounded-lg p-8 shadow-sm">
            <h3 className="text-xl font-bold mb-4">様々な職業の方が活躍中！</h3>
            <ul className="space-y-2">
              <li>👩‍⚕️ 看護師</li>
              <li>👩‍🍼 保育士</li>
              <li>👩‍⚕️ 介護士</li>
              <li>👩 専業主婦</li>
              <li>👩‍🎓 大学生</li>
              <li>👩‍🎓 専門学生</li>
            </ul>
          </div>
          <div className="bg-white rounded-lg p-8 shadow-sm">
            <h3 className="text-xl font-bold mb-4">3つの「O」で挫折した方も...</h3>
            <div className="space-y-4">
              <p>キャバ・ガルバ・コンカフェで働いていた方の中で...</p>
              <ul className="space-y-2">
                <li>🍺 お酒ムリ</li>
                <li>😴 おねむな夜勤</li>
                <li>💸 大きく稼げない</li>
              </ul>
              <p className="text-lg font-bold mt-4">
                ぴえん組のみなさんも大活躍中 🥺
              </p>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default ActiveMembers;