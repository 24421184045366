// components/FAQ.jsx
import React from 'react';
import { HelpCircle } from 'lucide-react';
import Card from './Card';

const FAQ = () => {
  const faqs = [
    {
      question: "連絡先を聞き出す非常識なお客様が登場したらどうしよう＞＜",
      answer: "クリーンな環境を維持するため、当事務所ではキックを推奨しています！"
    },
    {
      question: "お給料はいつもらえますか？",
      answer: "日払い・週払い・月払いからお選びいただけます。日払いの場合は当日のお仕事が終わってからすぐにお支払い可能です。"
    },
    {
      question: "未経験でも稼げますか？",
      answer: "はい、未経験の方でも安心してお仕事を始められます。研修制度もあり、先輩スタッフが丁寧にサポートいたします。"
    }
  ];

  return (
    <section className="py-16 px-4 md:px-8 bg-gradient-to-br from-pink-50 to-purple-50">
      <div className="max-w-7xl mx-auto">
        <h2 className="text-3xl font-bold text-center mb-12">よくある質問</h2>
        <div className="space-y-4 max-w-3xl mx-auto">
          {faqs.map((faq, index) => (
            <Card key={index} className="p-6">
              <h3 className="font-bold text-lg flex items-center gap-2 mb-3">
                <HelpCircle className="text-pink-400 flex-shrink-0" size={20} />
                {faq.question}
              </h3>
              <p className="text-gray-600 ml-7">{faq.answer}</p>
            </Card>
          ))}
        </div>
      </div>
    </section>
  );
};

export default FAQ;