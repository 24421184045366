// components/IncomeExamples.jsx
import React from 'react';
import { DollarSign } from 'lucide-react';
import Card from './Card';

const IncomeExamples = () => {
  const examples = [
    {
      type: "平均時給",
      amount: "6,000円",
      features: [
        "未経験でもしっかり稼げる",
        "時給保証あり",
        "ノルマなし"
      ]
    },
    {
      type: "月収例",
      amount: "50万円以上可能",
      details: "週3日・1日3時間の場合",
      features: [
        "インセンティブ制度あり",
        "ボーナスあり",
        "ライフスタイルに合わせて働けます"
      ]
    }
  ];

  return (
    <section className="py-16 px-4 md:px-8 bg-gradient-to-br from-pink-50 to-white">
      <div className="max-w-7xl mx-auto">
        <h2 className="text-3xl font-bold text-center mb-12">
          <DollarSign className="inline-block mr-2 mb-1" />
          収入例
        </h2>
        <div className="grid grid-cols-1 md:grid-cols-2 gap-8">
          {examples.map((example, index) => (
            <Card key={index} className="p-8">
              <div className="text-lg font-bold text-pink-500 mb-2">{example.type}</div>
              <div className="text-4xl font-bold mb-2">{example.amount}</div>
              {example.details && (
                <div className="text-gray-600 mb-4">{example.details}</div>
              )}
              <ul className="space-y-2">
                {example.features.map((feature, idx) => (
                  <li key={idx} className="flex items-center gap-2">
                    <div className="text-pink-500">●</div>
                    <span>{feature}</span>
                  </li>
                ))}
              </ul>
            </Card>
          ))}
        </div>
      </div>
    </section>
  );
};

export default IncomeExamples;