// components/Card.jsx
import React from 'react';

const Card = ({ children, className = '', ...props }) => (
  <div 
    className={`bg-white rounded-lg shadow-sm ${className}`} 
    {...props}
  >
    {children}
  </div>
);

export default Card;