// components/FloatingApplyButton.jsx
import React from 'react';
import { Link } from 'react-router-dom';
import Button from './Button';

const FloatingApplyButton = () => {
  return (
    <div className="fixed bottom-4 left-1/2 transform -translate-x-1/2 z-50 w-[90%] max-w-md">
      <Link to="/inquiry">
        <Button className="w-full bg-pink-500 hover:bg-pink-600 text-white py-4 text-base md:text-lg shadow-lg">
          🐺高収入ハンター志願🐻
        </Button>
      </Link>
    </div>
  );
};

export default FloatingApplyButton;