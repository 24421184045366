// components/FeatureCard.jsx
import React from 'react';
import Card from './Card';

const FeatureCard = ({ title, description, imageUrl, icon }) => (
  <Card className="overflow-hidden">
    {imageUrl && (
      <div className="relative h-48">
        <img src={imageUrl} alt={title} className="w-full h-full object-cover" />
      </div>
    )}
    <div className="p-6">
      <div className="flex items-start gap-3">
        {icon && <div className="text-2xl">{icon}</div>}
        <div>
          <h3 className="font-bold text-lg mb-2">{title}</h3>
          <p className="text-gray-600">{description}</p>
        </div>
      </div>
    </div>
  </Card>
);

export default FeatureCard;