// components/MusicPlayer.jsx
import React, { useState, useRef, useEffect } from 'react';
import { Play, Pause } from 'lucide-react';
import Modal from './Modal';

const MusicPlayer = () => {
  const [isLyricsOpen, setIsLyricsOpen] = useState(false);
  const [isPlaying, setIsPlaying] = useState(false);
  const audioRef = useRef(new Audio('/moemikai.mp3'));

  const handlePlay = () => {
    if (isPlaying) {
      audioRef.current.pause();
    } else {
      audioRef.current.play();
    }
    setIsPlaying(!isPlaying);
  };

  useEffect(() => {
    const audio = audioRef.current;
    const handleEnded = () => {
      setIsPlaying(false);
    };

    audio.addEventListener('ended', handleEnded);
    return () => {
      audio.removeEventListener('ended', handleEnded);
      audio.pause();
    };
  }, []);

  return (
    <div className="bg-white py-6 px-8 relative z-[5] flex items-center justify-between">
      <span className="text-base">もえみ会の歌を聞いとく❗️</span>
      <div className="flex items-center gap-4">
        <button 
          onClick={handlePlay}
          className="w-10 h-10 bg-pink-400 hover:bg-pink-500 text-white rounded-full flex items-center justify-center"
        >
          {isPlaying ? <Pause size={20} /> : <Play size={20} />}
        </button>
        <button 
          onClick={() => setIsLyricsOpen(true)}
          className="text-pink-500 hover:text-pink-600 flex items-center"
        >
          歌詞
        </button>
      </div>

      <Modal isOpen={isLyricsOpen} onClose={() => setIsLyricsOpen(false)}>
        <h3 className="text-xl font-bold mb-4">もえみ会の歌 歌詞</h3>
        <pre className="whitespace-pre-wrap font-sans text-base">
{`M・O・E・M・I・K・A・I だったら
超超超超...超高収入ABC
M・O・E・M・I・K・A・I だったら
人生変わる暮らし ヤッタルデ!!

M・O・E・M・I・K・A・I...

女の子だけの求人 もえみ会
稼げるリモ活 もえみ会
検索するならさ もえみ会だよ？
覚えといてね もえみ会
Repeat after me（もえみ会）

リモ活でガチめに
高収入 ゲッチュー できるとこ
「も...もえみ会」だね チュッチュ♡
1day 体験入店で
日払い is 必殺 Get money
叶えちゃおうよ My dream

同じような時間 同じ日々
つまんなすぎて ビビってんの？
ヒマしてんなら もえみ会へ
レッツゴーだぜ

Specialな ライブチャット
月100万ハンター
ダイタンに目指しても いいんじゃない？
ライブ配信 スキマバイト
ダンシと 会わずに稼げる もえみ会

高収入 Get money
今日から 始めちゃおうよ
New my live starting!!

b-b-b-Bonus time!!
18歳以上 高校生不可
履歴書なんか マジで不要 
証明してよ マイナンバー 免許証 etc...
顔出しNG マスクもOK
ああ 人気者になりたいコは
バンバン 顔出して Get money!!
今日からあなたも もえみ会のLady!!
そうだ 印鑑も準備OK？
忘れないでね
もえみ会`}
        </pre>
      </Modal>
    </div>
  );
};

export default MusicPlayer;