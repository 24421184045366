// components/Header.jsx
import React from 'react';
import { Link } from 'react-router-dom';
import Button from './Button';

const Header = () => {
  return (
    <nav className="fixed top-0 left-0 right-0 z-50 bg-white/80 backdrop-blur-md">
      <div className="container mx-auto px-4 py-3">
        <div className="flex justify-between mb-1">
          <span className="text-xs text-gray-600">高収入リモ活事務所なら</span>
          <span className="text-xs text-gray-600">スキマバイト感覚で1日体験入店！</span>
        </div>
        <div className="flex justify-between items-center">
          <div className="flex-1">
            <Link 
              to="/" 
              className="text-2xl font-bold tracking-wider inline-block"
              style={{ 
                fontFamily: "'Shippori Mincho', serif",
                letterSpacing: '0.1em',
                paddingRight: '2em'
              }}
            >
              もえみ会
            </Link>
          </div>
          <Link to="/inquiry">
            <Button className="bg-pink-500 hover:bg-pink-600 text-white">
              応募に進む
            </Button>
          </Link>
        </div>
      </div>
    </nav>
  );
};

export default Header;