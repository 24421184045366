// components/StoreList.jsx
import React from 'react';
import { MapPin } from 'lucide-react';
import Card from './Card';

const StoreList = () => {
  return (
    <section className="bg-white py-16 px-4 md:px-8 mb-8">
      <div className="max-w-7xl mx-auto">
        <h2 className="text-2xl font-bold mb-8">
          <MapPin className="inline mr-2" />
          店舗一覧
        </h2>
        <div className="grid grid-cols-1 md:grid-cols-3 gap-8">
          <Card className="p-6">
            <h3 className="font-bold mb-4">東京都</h3>
            <p>上野駅前店、北千住店、秋葉原店、浅草駅前店、渋谷駅前店、新宿駅前店、大久保駅前店、池袋駅前店、町田駅前店、錦糸町店、清瀬店</p>
          </Card>
          <Card className="p-6">
            <h3 className="font-bold mb-4">関東エリア</h3>
            <p>柏店、所沢店、川崎駅前店、横浜駅前店、新百合ヶ丘店、本厚木店</p>
          </Card>
          <Card className="p-6">
            <h3 className="font-bold mb-4">その他エリア</h3>
            <p>札幌店、仙台駅前店、名古屋店、京都駅前店、大阪店、博多店、天神店 他</p>
          </Card>
        </div>
      </div>
    </section>
  );
};

export default StoreList;