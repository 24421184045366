// components/ExperienceFlow.jsx
import React from 'react';
import { Clock, ChevronRight } from 'lucide-react';

const ExperienceFlow = () => {
  const steps = [
    {
      number: "01",
      title: "LINE・電話で応募",
      description: "必要なのは年齢確認だけ！",
      subInfo: "持ち物：顔写真つき身分証明書（マイナンバー、免許証、パスポート、学生証）"
    },
    {
      number: "02",
      title: "来店・説明",
      description: "不安な点は何でも聞いてね",
      subInfo: "印鑑（日払いを受け取る際の受領印として）"
    },
    {
      number: "03",
      title: "体験スタート",
      description: "1日だけでもOK",
      subInfo: "そのまま入店も可能です"
    },
    {
      number: "04",
      title: "お給料GET",
      description: "最短即日からスタート",
      subInfo: "日払い対応可能"
    }
  ];

  return (
    <section className="py-16 px-4 md:px-8 bg-gradient-to-br from-white to-blue-50">
      <div className="max-w-7xl mx-auto">
        <div className="text-center mb-12">
          <h2 className="text-3xl font-bold">
            <Clock className="inline-block mr-2 mb-1" />
            スキマバイト感覚！1日体験の流れ
          </h2>
        </div>
        
        <div className="grid grid-cols-1 md:grid-cols-4 relative">
          {/* ステップカード */}
          {steps.map((step, index) => (
            <div key={index} className="p-4">
              <div className="bg-white p-6 rounded-lg shadow-sm h-full flex flex-col">
                <div className="text-pink-500 font-bold text-lg mb-2">STEP {step.number}</div>
                <h3 className="text-xl font-bold mb-2">{step.title}</h3>
                <p className="text-gray-600 mb-2">{step.description}</p>
                <p className="text-sm text-gray-500 mt-auto">{step.subInfo}</p>
              </div>
            </div>
          ))}
          
          {/* PC表示時の矢印 */}
          <div className="hidden md:block absolute top-0 left-0 w-full h-full pointer-events-none">
            <div className="grid grid-cols-4 h-full">
              {[0, 1, 2].map((index) => (
                <div key={index} className="relative">
                  <div className="absolute top-1/2 right-[-12px] transform -translate-y-1/2">
                    <ChevronRight className="text-pink-400" size={28} />
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default ExperienceFlow;