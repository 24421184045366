// components/KeyVisual.jsx
import React, { useState, useEffect } from 'react';

const KeyVisual = () => {
  const [isMobile, setIsMobile] = useState(window.innerWidth < 768);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < 768);
    };

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  return (
    <div className="w-full pt-16">
      <div className="w-full relative">
        {isMobile ? (
          <div className="relative w-full">
            <img 
              src="/kv-sp.jpg" 
              alt="女の子だけの高収入スキマバイト。チャットレディ求人なら「もえみ会」"
              className="w-full h-auto"
            />
            <div className="absolute bottom-0 left-0 right-0 h-6" />
          </div>
        ) : (
          <div className="relative w-full">
            <img 
              src="/kv-pc.jpg" 
              alt="女の子だけの高収入スキマバイト。チャットレディ求人なら「もえみ会」"
              className="w-full h-auto"
            />
            <div className="absolute bottom-0 left-0 right-0 h-8" />
          </div>
        )}
      </div>
    </div>
  );
};

export default KeyVisual;