// components/RemoteWorkIntro.jsx
import React from 'react';
import Card from './Card';

const RemoteWorkIntro = () => {
  return (
    <section className="py-16 px-4 md:px-8 bg-white">
      <div className="max-w-7xl mx-auto">
        <h2 className="text-3xl font-bold text-center mb-8">リモ活とは</h2>
        <Card className="p-8 max-w-3xl mx-auto">
          <p className="text-lg leading-relaxed">
            自分の好きな時間に、男性のお客様へライブ配信をするお仕事！
            快適な専用ブースからパソコンの画面越しにお客様とおしゃべり。
            会話時間に応じて収入が発生するシステムだから、投げ銭やギフトに左右されることなく、
            しっかり高収入をGET♪
          </p>
          <div className="mt-4 text-sm text-gray-500">
            ※「チャットレディ」の仕事を令和っぽくキャッチーに言い換えたものです
          </div>
        </Card>
      </div>
    </section>
  );
};

export default RemoteWorkIntro;