// components/WorkStyle.jsx
import React from 'react';
import Card from './Card';

const WorkStyle = () => {
  return (
    <section className="py-16 px-4 md:px-8 bg-white">
      <div className="max-w-7xl mx-auto">
        <h2 className="text-3xl font-bold text-center mb-12">働き方は自由自在</h2>
        <div className="grid grid-cols-1 md:grid-cols-2 gap-8">
          <Card className="p-8">
            <h3 className="text-xl font-bold mb-4">選べる働き方</h3>
            <ul className="space-y-4">
              <li className="flex items-start gap-3">
                <div className="text-pink-500 mt-1">●</div>
                <p>ノンアダルト/アダルト どっちも行き来OK</p>
              </li>
              <li className="flex items-start gap-3">
                <div className="text-pink-500 mt-1">●</div>
                <p>チャットブース通勤・在宅 行き来OK<br/>
                   <span className="text-sm text-gray-500">
                     最初は通勤、慣れてきたら在宅、気分転換に通勤、など柔軟に対応可能
                   </span>
                </p>
              </li>
              <li className="flex items-start gap-3">
                <div className="text-pink-500 mt-1">●</div>
                <p>お酒の力を借りることもできる<br/>
                   <span className="text-sm text-gray-500">
                     2shotチャットならオンラインキャバ感覚で
                   </span>
                </p>
              </li>
            </ul>
          </Card>
          <Card className="p-8">
            <h3 className="text-xl font-bold mb-4">顔出しについて</h3>
            <ul className="space-y-4">
              <li className="flex items-start gap-3">
                <div className="text-pink-500 mt-1">●</div>
                <p>マスクでもOK</p>
              </li>
              <li className="flex items-start gap-3">
                <div className="text-pink-500 mt-1">●</div>
                <p>Tシャツに自画像書いてアピールもできる</p>
              </li>
              <li className="flex items-start gap-3">
                <div className="text-pink-500 mt-1">●</div>
                <p>なんだってできる！</p>
              </li>
            </ul>
          </Card>
        </div>
      </div>
    </section>
  );
};

export default WorkStyle;