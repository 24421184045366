// pages/Shop.jsx
import React from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { Helmet } from 'react-helmet-async';
import Header from '../components/Header';
import Card from '../components/Card';
import { MapPin, Clock, Train } from 'lucide-react';

const Shop = () => {
  const { id } = useParams();
  const navigate = useNavigate();

  // テスト用の店舗データ
  const shops = {
    "ueno": {
      name: "上野駅前店",
      area: "東京都",
      address: "台東区上野X-XX-XX XXビル5F",
      access: "上野駅徒歩3分",
      hours: "10:00～22:00",
      features: [
        "完全個室のチャットルーム",
        "ドリンクバー完備",
        "Wi-Fi完備",
        "お菓子食べ放題",
        "メイクルーム完備"
      ],
      images: [
        "/api/placeholder/400/300",
        "/api/placeholder/400/300",
      ]
    },
    "akiba": {
      name: "秋葉原店",
      area: "東京都",
      address: "千代田区外神田X-XX-XX XXビル3F",
      access: "秋葉原駅徒歩4分",
      hours: "10:00～22:00",
      features: [
        "防音完備の個室",
        "ドリンクバー完備",
        "Wi-Fi完備",
        "休憩スペース完備"
      ],
      images: [
        "/api/placeholder/400/300",
        "/api/placeholder/400/300",
      ]
    }
  };

  // 店舗一覧ページの表示
  if (!id) {
    return (
      <>
        <Helmet>
          <title>店舗一覧 - もえみ会</title>
          <meta name="description" content="もえみ会の店舗一覧ページです。全国各地の店舗情報をご確認いただけます。" />
        </Helmet>

        <div className="min-h-screen bg-gradient-to-br from-white to-pink-50">
          <Header />
          
          <div className="pt-28 px-4 max-w-7xl mx-auto pb-20">
            <h1 className="text-3xl font-bold mb-8">店舗一覧</h1>
            
            <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6">
              {Object.entries(shops).map(([shopId, shop]) => (
                <Card 
                  key={shopId}
                  className="cursor-pointer hover:shadow-md transition-shadow"
                  onClick={() => navigate(`/shops/${shopId}`)}
                >
                  <img 
                    src={shop.images[0]} 
                    alt={shop.name} 
                    className="w-full h-48 object-cover rounded-t-lg"
                  />
                  <div className="p-6">
                    <div className="text-sm text-gray-500 mb-2">{shop.area}</div>
                    <h2 className="text-xl font-bold mb-2">{shop.name}</h2>
                    <div className="flex items-center text-gray-600 text-sm">
                      <Train className="w-4 h-4 mr-1" />
                      {shop.access}
                    </div>
                  </div>
                </Card>
              ))}
            </div>
          </div>
        </div>
      </>
    );
  }

  // 店舗が存在しない場合
  if (!shops[id]) {
    return (
      <>
        <Helmet>
          <title>店舗が見つかりません - もえみ会</title>
        </Helmet>

        <div className="min-h-screen bg-gradient-to-br from-white to-pink-50">
          <Header />
          
          <div className="pt-28 px-4 max-w-3xl mx-auto pb-20 text-center">
            <h1 className="text-3xl font-bold mb-4">店舗が見つかりません</h1>
            <p className="mb-8">お探しの店舗は存在しないか、移転した可能性があります。</p>
            <button 
              onClick={() => navigate('/shops')}
              className="text-pink-500 hover:text-pink-600"
            >
              店舗一覧に戻る
            </button>
          </div>
        </div>
      </>
    );
  }

  // 個別店舗ページの表示
  const shop = shops[id];
  
  return (
    <>
      <Helmet>
        <title>{`${shop.name} - もえみ会`}</title>
        <meta name="description" content={`もえみ会${shop.name}の店舗情報です。${shop.access}で通いやすい場所にあります。`} />
      </Helmet>

      <div className="min-h-screen bg-gradient-to-br from-white to-pink-50">
        <Header />
        
        <div className="pt-28 px-4 max-w-4xl mx-auto pb-20">
          <Card className="overflow-hidden">
            {/* 店舗画像スライダー */}
            <div className="relative h-64 md:h-96">
              <img 
                src={shop.images[0]} 
                alt={shop.name} 
                className="w-full h-full object-cover"
              />
            </div>
            
            {/* 店舗情報 */}
            <div className="p-8">
              <h1 className="text-3xl font-bold mb-6">{shop.name}</h1>
              
              <div className="space-y-4">
                <div className="flex items-start gap-2">
                  <MapPin className="w-5 h-5 text-pink-500 mt-1" />
                  <div>
                    <div className="font-bold">住所</div>
                    <div>{shop.address}</div>
                    <div className="text-gray-600">{shop.access}</div>
                  </div>
                </div>

                <div className="flex items-start gap-2">
                  <Clock className="w-5 h-5 text-pink-500 mt-1" />
                  <div>
                    <div className="font-bold">営業時間</div>
                    <div>{shop.hours}</div>
                  </div>
                </div>

                <div>
                  <h2 className="font-bold text-xl mb-3">店舗の特徴</h2>
                  <ul className="grid grid-cols-1 md:grid-cols-2 gap-2">
                    {shop.features.map((feature, index) => (
                      <li key={index} className="flex items-center gap-2">
                        <span className="text-pink-500">●</span>
                        {feature}
                      </li>
                    ))}
                  </ul>
                </div>
              </div>
            </div>
          </Card>
        </div>
      </div>
    </>
  );
};

export default Shop;