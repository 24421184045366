// pages/Inquiry.jsx
import React, { useState } from 'react';
import { Send, Phone, Mail, MapPin } from 'lucide-react';
import { Helmet } from 'react-helmet-async';
import Header from '../components/Header';
import Card from '../components/Card';
import Button from '../components/Button';

const Inquiry = () => {
  const [formData, setFormData] = useState({
    name: '',
    age: '',
    phone: '',
    email: '',
    workLocation: '',
    workStyle: '',
    availableTime: '',
    message: ''
  });

  const [submitted, setSubmitted] = useState(false);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData(prev => ({
      ...prev,
      [name]: value
    }));
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    // ここに送信処理を実装
    console.log('送信されたデータ:', formData);
    setSubmitted(true);
  };

  if (submitted) {
    return (
      <>
        <Helmet>
          <title>チャットレディ求人応募フォーム - 高収入リモ活事務所なら「もえみ会」。通勤・在宅OKのバイト募集・チャットレディ求人情報</title>
          {/* 基本的なmeta */}
          <meta name="description" content="【公式】リモ活事務所「もえみ会」もえみ会の応募フォームページです。お気軽にご応募ください。【公式】リモ活で稼ぐならリモ活事務所「もえみ会」におまかせ！通勤・在宅OKの高収入チャットレディ求人情報を多数ご用意。" />
          <meta name="keywords" content="リモ活, チャットレディ, 高収入求人募集" />
          <meta name="author" content="もえみちゃん" />
          <meta name="robots" content="index, follow" />

          {/* OGP */}
          <meta property="og:title" content="応募フォーム - 高収入リモ活事務所なら「もえみ会」。通勤・在宅OKのバイト募集・チャットレディ求人情報" />
          <meta property="og:description" content="【公式】リモ活事務所「もえみ会」もえみ会の応募フォームページです。お気軽にご応募ください。【公式】リモ活で稼ぐならリモ活事務所「もえみ会」におまかせ！通勤・在宅OKの高収入チャットレディ求人情報を多数ご用意。" />
          <meta property="og:image" content="https://moemikai.com/ogp.jpg" />
          <meta property="og:url" content="https://moemikai.com/inquiry" />
          <meta property="og:type" content="website" />
          <meta name="twitter:card" content="summary_large_image" />

          {/* Google Analytics */}
          <script async src="https://www.googletagmanager.com/gtag/js?id=G-E4FYRH3LNL"></script>
          <script>
            {`
              window.dataLayer = window.dataLayer || [];
              function gtag(){dataLayer.push(arguments);}
              gtag('js', new Date());
              gtag('config', 'G-E4FYRH3LNL');
            `}
          </script>
        </Helmet>
        <div className="min-h-screen bg-gradient-to-br from-white to-pink-50">
          <Header />
          <div className="pt-28 px-4 max-w-2xl mx-auto">
            <Card className="p-8 text-center">
              <h2 className="text-2xl font-bold mb-4">応募ありがとうございます！</h2>
              <p className="mb-4">担当者より順次ご連絡させていただきます。</p>
              <p className="text-sm text-gray-500">
                ※ご連絡まで通常1営業日程度いただいております。
                <br />
                お急ぎの方はお電話でのご応募もご検討ください。
              </p>
            </Card>
          </div>
        </div>
      </>
    );
  }
  
  
  
return (
    <>
      <Helmet>
        <title>チャットレディ求人応募フォーム - 高収入リモ活事務所なら「もえみ会」。通勤・在宅OKのバイト募集・チャットレディ求人情報</title>
        {/* 基本的なmeta */}
        <meta name="description" content="【公式】リモ活事務所「もえみ会」もえみ会の応募フォームページです。お気軽にご応募ください。【公式】リモ活で稼ぐならリモ活事務所「もえみ会」におまかせ！通勤・在宅OKの高収入チャットレディ求人情報を多数ご用意。" />
        <meta name="keywords" content="リモ活, チャットレディ, 高収入求人募集" />
        <meta name="author" content="もえみちゃん" />
        <meta name="robots" content="index, follow" />

        {/* OGP */}
        <meta property="og:title" content="応募フォーム - 高収入リモ活事務所なら「もえみ会」。通勤・在宅OKのバイト募集・チャットレディ求人情報" />
        <meta property="og:description" content="【公式】リモ活事務所「もえみ会」もえみ会の応募フォームページです。お気軽にご応募ください。【公式】リモ活で稼ぐならリモ活事務所「もえみ会」におまかせ！通勤・在宅OKの高収入チャットレディ求人情報を多数ご用意。" />
        <meta property="og:image" content="https://moemikai.com/ogp.jpg" />
        <meta property="og:url" content="https://moemikai.com/inquiry" />
        <meta property="og:type" content="website" />
        <meta name="twitter:card" content="summary_large_image" />

        {/* Google Analytics */}
        <script async src="https://www.googletagmanager.com/gtag/js?id=G-E4FYRH3LNL"></script>
        <script>
          {`
            window.dataLayer = window.dataLayer || [];
            function gtag(){dataLayer.push(arguments);}
            gtag('js', new Date());
            gtag('config', 'G-E4FYRH3LNL');
          `}
        </script>
      </Helmet>

      <div className="min-h-screen bg-gradient-to-br from-white to-pink-50">
        <Header />
        
        <div className="pt-28 px-4 pb-20">
          <div className="max-w-4xl mx-auto">
            <h1 className="text-3xl font-bold text-center mb-8">応募フォーム</h1>

            {/* 応募方法カード */}
            <div className="grid grid-cols-1 md:grid-cols-3 gap-4 mb-12">
              <Card className="p-6 text-center">
                <Phone className="mx-auto mb-4 text-pink-500" size={24} />
                <h3 className="font-bold mb-2">電話で応募</h3>
                <p className="text-pink-500 font-bold">0120-xxx-xxx</p>
                <p className="text-sm text-gray-500 mt-2">24時間受付中</p>
              </Card>
              
              <Card className="p-6 text-center">
                <Mail className="mx-auto mb-4 text-pink-500" size={24} />
                <h3 className="font-bold mb-2">メールで応募</h3>
                <p className="text-pink-500 font-bold">info@moemikai.com</p>
                <p className="text-sm text-gray-500 mt-2">24時間受付中</p>
              </Card>
              
              <Card className="p-6 text-center">
                <MapPin className="mx-auto mb-4 text-pink-500" size={24} />
                <h3 className="font-bold mb-2">LINEで応募</h3>
                <p className="text-pink-500 font-bold">@moemikai</p>
                <p className="text-sm text-gray-500 mt-2">24時間受付中</p>
              </Card>
            </div>

{/* 応募フォーム */}
            <Card className="p-8">
              <h2 className="text-2xl font-bold mb-6">Web応募フォーム</h2>
              <form onSubmit={handleSubmit} className="space-y-6">
                <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
                  <div>
                    <label className="block text-sm font-medium mb-2">
                      お名前 <span className="text-pink-500">*</span>
                    </label>
                    <input
                      type="text"
                      name="name"
                      required
                      className="w-full px-4 py-2 border rounded-lg focus:ring-2 focus:ring-pink-500 focus:border-transparent"
                      value={formData.name}
                      onChange={handleChange}
                    />
                  </div>

                  <div>
                    <label className="block text-sm font-medium mb-2">
                      年齢 <span className="text-pink-500">*</span>
                    </label>
                    <input
                      type="number"
                      name="age"
                      required
                      min="18"
                      className="w-full px-4 py-2 border rounded-lg focus:ring-2 focus:ring-pink-500 focus:border-transparent"
                      value={formData.age}
                      onChange={handleChange}
                    />
                  </div>

                  <div>
                    <label className="block text-sm font-medium mb-2">
                      電話番号 <span className="text-pink-500">*</span>
                    </label>
                    <input
                      type="tel"
                      name="phone"
                      required
                      className="w-full px-4 py-2 border rounded-lg focus:ring-2 focus:ring-pink-500 focus:border-transparent"
                      value={formData.phone}
                      onChange={handleChange}
                    />
                  </div>

                  <div>
                    <label className="block text-sm font-medium mb-2">
                      メールアドレス
                    </label>
                    <input
                      type="email"
                      name="email"
                      className="w-full px-4 py-2 border rounded-lg focus:ring-2 focus:ring-pink-500 focus:border-transparent"
                      value={formData.email}
                      onChange={handleChange}
                    />
                  </div>

                  <div>
                    <label className="block text-sm font-medium mb-2">
                      希望の勤務地 <span className="text-pink-500">*</span>
                    </label>
                    <select
                      name="workLocation"
                      required
                      className="w-full px-4 py-2 border rounded-lg focus:ring-2 focus:ring-pink-500 focus:border-transparent"
                      value={formData.workLocation}
                      onChange={handleChange}
                    >
                      <option value="">選択してください</option>
                      <option value="tokyo">東京エリア</option>
                      <option value="kanto">関東エリア</option>
                      <option value="other">その他エリア</option>
                    </select>
                  </div>

                  <div>
                    <label className="block text-sm font-medium mb-2">
                      希望の働き方 <span className="text-pink-500">*</span>
                    </label>
                    <select
                      name="workStyle"
                      required
                      className="w-full px-4 py-2 border rounded-lg focus:ring-2 focus:ring-pink-500 focus:border-transparent"
                      value={formData.workStyle}
                      onChange={handleChange}
                    >
                      <option value="">選択してください</option>
                      <option value="store">店舗で働く</option>
                      <option value="remote">在宅で働く</option>
                      <option value="both">両方検討したい</option>
                    </select>
                  </div>
                </div>

                <div>
                  <label className="block text-sm font-medium mb-2">
                    ご希望の連絡時間帯
                  </label>
                  <input
                    type="text"
                    name="availableTime"
                    placeholder="例：18時以降"
                    className="w-full px-4 py-2 border rounded-lg focus:ring-2 focus:ring-pink-500 focus:border-transparent"
                    value={formData.availableTime}
                    onChange={handleChange}
                  />
                </div>

                <div>
                  <label className="block text-sm font-medium mb-2">
                    ご質問・ご要望
                  </label>
                  <textarea
                    name="message"
                    rows="4"
                    className="w-full px-4 py-2 border rounded-lg focus:ring-2 focus:ring-pink-500 focus:border-transparent"
                    value={formData.message}
                    onChange={handleChange}
                  />
                </div>

                <div className="text-center">
                  <Button 
                    type="submit" 
                    className="bg-pink-500 hover:bg-pink-600 text-white px-8 py-3"
                  >
                    <Send className="inline-block mr-2" size={18} />
                    送信する
                  </Button>
                </div>
              </form>
            </Card>

            {/* プライバシーポリシー */}
            <div className="mt-8 text-sm text-gray-500 text-center">
              <p>※ご入力いただいた個人情報は、お問い合わせへの対応以外の目的では使用いたしません。</p>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Inquiry;