// pages/Article.jsx
import React from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { Helmet } from 'react-helmet-async';
import Header from '../components/Header';

const Article = () => {
  const { id } = useParams();
  const navigate = useNavigate();

  // テスト用の記事データ集
  const articles = {
    "1": {
      title: "リモ活の始め方完全ガイド",
      publishDate: "2024.12.15",
      content: `
        リモ活を始めるにあたって、よくある質問や心配事を徹底解説します。
        
        【目次】
        1. リモ活とは
        2. 必要な準備物
        3. 収入シミュレーション
        4. よくある不安と解決方法
        
        まずは...
      `,
    },
    "2": {
      title: "在宅で稼ぐコツ",
      publishDate: "2024.12.14",
      content: "在宅でしっかり稼ぐためのコツを紹介します...",
    }
  };

  // 記事一覧ページの表示
  if (!id) {
    return (
      <>
        <Helmet>
          <title>記事一覧 - もえみ会</title>
          <meta name="description" content="もえみ会の記事一覧ページです。" />
        </Helmet>

        <div className="min-h-screen bg-gradient-to-br from-white to-pink-50">
          <Header />
          
          <div className="pt-28 px-4 max-w-3xl mx-auto pb-20">
            <h1 className="text-3xl font-bold mb-8">記事一覧</h1>
            <div className="space-y-4">
              {Object.entries(articles).map(([articleId, article]) => (
                <div 
                  key={articleId}
                  className="bg-white rounded-lg shadow-sm p-6 cursor-pointer hover:shadow-md transition-shadow"
                  onClick={() => navigate(`/articles/${articleId}`)}
                >
                  <h2 className="text-xl font-bold mb-2">{article.title}</h2>
                  <div className="text-gray-500">{article.publishDate}</div>
                </div>
              ))}
            </div>
          </div>
        </div>
      </>
    );
  }

  // 記事が存在しない場合
  if (!articles[id]) {
    return (
      <>
        <Helmet>
          <title>記事が見つかりません - もえみ会</title>
        </Helmet>

        <div className="min-h-screen bg-gradient-to-br from-white to-pink-50">
          <Header />
          
          <div className="pt-28 px-4 max-w-3xl mx-auto pb-20 text-center">
            <h1 className="text-3xl font-bold mb-4">記事が見つかりません</h1>
            <p className="mb-8">お探しの記事は存在しないか、削除された可能性があります。</p>
            <button 
              onClick={() => navigate('/articles')}
              className="text-pink-500 hover:text-pink-600"
            >
              記事一覧に戻る
            </button>
          </div>
        </div>
      </>
    );
  }

  // 個別記事ページの表示
  const article = articles[id];
  
  return (
    <>
      <Helmet>
        <title>{article.title} - もえみ会</title>
        <meta name="description" content={`${article.title}の詳細記事です。`} />
      </Helmet>

      <div className="min-h-screen bg-gradient-to-br from-white to-pink-50">
        <Header />
        
        <div className="pt-28 px-4 max-w-3xl mx-auto pb-20">
          <article className="bg-white rounded-lg shadow-sm p-8">
            <h1 className="text-3xl font-bold mb-4">{article.title}</h1>
            <div className="text-gray-500 mb-8">{article.publishDate}</div>
            <div className="prose max-w-none">
              {article.content}
            </div>
          </article>
        </div>
      </div>
    </>
  );
};

export default Article;