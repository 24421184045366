import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import { HelmetProvider } from 'react-helmet-async';

// ページコンポーネントのインポート
import Home from './pages/Home';
import Inquiry from './pages/Inquiry';
import Article from './pages/Article';
import Shop from './pages/Shop';

function App() {
  return (
    <HelmetProvider>
      <Router>
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/inquiry" element={<Inquiry />} />
          <Route path="/articles" element={<Article />} />
          <Route path="/articles/:id" element={<Article />} />
          <Route path="/shops" element={<Shop />} />
          <Route path="/shops/:id" element={<Shop />} />
        </Routes>
      </Router>
    </HelmetProvider>
  );
}

export default App;