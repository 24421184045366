// pages/Home.jsx
import React from 'react';
import { Helmet } from 'react-helmet-async';

// 全コンポーネントのインポート
import Header from '../components/Header';
import KeyVisual from '../components/KeyVisual';
import MusicPlayer from '../components/MusicPlayer';
import EmuDecoration from '../components/EmuDecoration';
import Strengths from '../components/Strengths';
import ChatroomFeatures from '../components/ChatroomFeatures';
import ActiveMembers from '../components/ActiveMembers';
import ExperienceFlow from '../components/ExperienceFlow';
import WorkStyle from '../components/WorkStyle';
import IncomeExamples from '../components/IncomeExamples';
import FAQ from '../components/FAQ';
import RemoteWorkIntro from '../components/RemoteWorkIntro';
import StoreList from '../components/StoreList';
import FloatingApplyButton from '../components/FloatingApplyButton';



const Home = () => {
  return (
  
     <>
      <Helmet>
        <title>高収入リモ活事務所なら「もえみ会」。通勤・在宅OKのバイト募集・チャットレディ求人情報</title>
        {/* 基本的なmeta */}
        <meta name="description" content="【公式】リモ活で稼ぐならリモ活事務所「もえみ会」におまかせ！通勤・在宅OKの高収入チャットレディ求人情報を多数ご用意。" />
        <meta name="keywords" content="リモ活, チャットレディ, 高収入求人募集" />
        <meta name="author" content="もえみちゃん" />
        <meta name="robots" content="index, follow" />

        {/* OGP */}
        <meta property="og:title" content="リモ活とは？ - 高収入リモ活事務所なら「もえみ会」。通勤・在宅OKのバイト募集・チャットレディ求人情報" />
        <meta property="og:description" content="もえみ会は、高収入を目指す女の子のためのリモ活事務所です。通勤・在宅OKの求人情報を多数ご用意。" />
        <meta property="og:image" content="https://moemikai.com/ogp.jpg" />
        <meta property="og:url" content="https://moemikai.com/" />
        <meta property="og:type" content="website" />
        <meta name="twitter:card" content="summary_large_image" />

        {/* Google Analytics */}
        <script async src="https://www.googletagmanager.com/gtag/js?id=G-E4FYRH3LNL"></script>
        <script>
          {`
            window.dataLayer = window.dataLayer || [];
            function gtag(){dataLayer.push(arguments);}
            gtag('js', new Date());
            gtag('config', 'G-E4FYRH3LNL');
          `}
        </script>
      </Helmet>
  
  
	  <div className="min-h-screen bg-gradient-to-br from-white to-pink-50">

    
      {/* ヘッダー */}
      <Header />

      {/* メインビジュアル */}
      <KeyVisual />

      <main>
        {/* 音楽プレーヤー */}
        <MusicPlayer />
        
        {/* エミューの装飾 */}
        <EmuDecoration />
        
        {/* コンテンツセクション */}
        <Strengths />
        <ChatroomFeatures />
        <ActiveMembers />
        <ExperienceFlow />
        <WorkStyle />
        <IncomeExamples />
        <FAQ />
        <RemoteWorkIntro />
        <StoreList />
      </main>

      {/* フローティング応募ボタン */}
      <FloatingApplyButton />
    </div>
    </>
  );
};

export default Home;