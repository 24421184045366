// components/EmuDecoration.jsx
import React from 'react';

const EmuDecoration = () => {
  return (
    <div className="rounded-lg p-6 mb-8 bg-gradient-to-br from-pink-50 to-white">
      <div className="mt-8 relative text-center mb-2">
        <div className="inline-block bg-pink-500 text-white px-3 py-2 rounded-full relative">
          <span className="text-sm md:text-lg font-bold whitespace-nowrap">＼人生変わる暮らし ヤッタルデ!!／</span>
          <div 
            className="absolute -bottom-2 left-1/2 transform -translate-x-1/2 w-4 h-4 bg-pink-500"
            style={{
              clipPath: 'polygon(50% 100%, 0 0, 100% 0)'
            }} 
          />
        </div>
      </div>
      <div className="flex justify-center items-center py-4">
        <div className="w-40 h-40 md:w-48 md:h-48 relative">
          <img
            src="/emu.gif" 
            alt="エミュー"
            className="w-full h-full object-contain"
          />
        </div>
      </div>
    </div>
  );
};

export default EmuDecoration;