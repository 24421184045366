// components/Strengths.jsx
import React from 'react';
import FeatureCard from './FeatureCard';
import Button from './Button';

const Strengths = () => {
  const features = [
    {
      title: "ガチめな身バレ対策",
      description: "スタッフの顔出しは絶対しません。恥ずかしいから？いいえ、在籍スタッフ＆リモ活女子のためです！当社のサイトを見てもらうとおわかりの通り、徹底した身バレ対策を行っています。",
      imageUrl: "/api/placeholder/400/300",
      icon: "🔒"
    },
    {
      title: "一匹狼🐺高収入ハンター",
      description: "面倒な指示は一切なし。お客様と直接会わないからといって、関係性を深めるために彼女のふりをするような指示もありません。上に縛られずに会話を楽しむ、それが高報酬事務所の強みです。",
      imageUrl: "/api/placeholder/400/300",
      icon: "🐺"
    },
    {
      title: "個性と自由を尊重",
      description: "稼ぐためのアドバイスやヒントは、スタッフとの雑談の中でうっかりこぼれたり...？「どうやって稼げばいいですか」と聞いてみるのもアリです。",
      imageUrl: "/api/placeholder/400/300",
      icon: "✨"
    },
    {
      title: "キャリアアップ制度",
      description: "チャットレディからスタッフ、マネージャーになることもできます。チャットレディで貯めた資金で自分のお店を持つことも可能です。",
      imageUrl: "/api/placeholder/400/300",
      icon: "📈"
    }
  ];

  return (
    <section className="py-16 px-4 md:px-8 bg-gradient-to-br from-blue-50 to-pink-50">
      <div className="max-w-7xl mx-auto">
        <h2 className="text-3xl font-bold text-center mb-4">もえみ会の強み</h2>
        <p className="text-center text-gray-600 mb-12">
          選ばれる理由があります
        </p>
        <div className="grid grid-cols-1 md:grid-cols-2 gap-8">
          {features.map((feature, index) => (
            <FeatureCard key={index} {...feature} />
          ))}
        </div>
        <div className="mt-8 bg-white rounded-lg p-6 shadow-sm">
          <h3 className="font-bold text-xl mb-4">出店制度について</h3>
          <p className="text-gray-600 mb-4">
            100万円～の自己資金で、チャットルームを開業できます。
            満20歳以上の方で、経営に興味がある方、性別不問。個人・法人は問いません。
          </p>
          <Button className="text-pink-500 hover:text-pink-600">
            オーナー募集はこちら →
          </Button>
        </div>
      </div>
    </section>
  );
};

export default Strengths;